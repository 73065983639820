import moment from "moment";
import { BASE_URL } from "../../../global";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import { toast } from "react-toastify";
import compareOldAndNewReadings from "../../../utils/components/compareoldandnewReadings";


const submitRanges = async (
  insertedID,
  readingRows,
  readingRangeValue,
  params,
  props,
  percent
) => {
  let rows = [];
  if (params.id == 0) {
    if (percent == false) {
      rows = readingRows.map((row, i) => ({
        standardId: Number(insertedID),
        rangeName: `${row[1]}#${
          readingRangeValue[i][1] != null ? readingRangeValue[i][1] : ""
        }|${row[2]}#${
          readingRangeValue[i][2] != null ? readingRangeValue[i][2] : ""
        }`,
        mode: row[3],
        parameter: row[4],
        paratype: row[5],
        uncertainty:
          readingRangeValue[i][6] != null &&
          readingRangeValue[i][6].length > 0 &&
          row[6] != ""
            ? row[6] + "#" + readingRangeValue[i][6]
            : row[6],
        accuracy:
          readingRangeValue[i][7] != null &&
          readingRangeValue[i][7].length > 0 &&
          row[7] != ""
            ? row[7] + "#" + readingRangeValue[i][7]
            : row[7],
        percentOfOutput:
          readingRangeValue[i][8] != null &&
          readingRangeValue[i][8].length > 0 &&
          row[8] != ""
            ? row[8] + "#" + readingRangeValue[i][8]
            : row[8],
        plusValue:
          readingRangeValue[i][9] != null &&
          readingRangeValue[i][9].length > 0 &&
          row[9] != ""
            ? row[9] + "#" + readingRangeValue[i][9]
            : row[9],
        stability:
          readingRangeValue[i][10] != null &&
          readingRangeValue[i][10].length > 0 &&
          row[10] != ""
            ? row[10] + "#" + readingRangeValue[i][10]
            : row[10],
        uniformity:
          readingRangeValue[i][11] != null &&
          readingRangeValue[i][11].length > 0 &&
          row[11] != ""
            ? row[11] + "#" + readingRangeValue[i][11]
            : row[11],
        axialUniformity:
          readingRangeValue[i][12] != null &&
          readingRangeValue[i][12].length > 0 &&
          row[12] != ""
            ? row[12] + "#" + readingRangeValue[i][12]
            : row[12],
        radialUniformity:
          readingRangeValue[i][13] != null &&
          readingRangeValue[i][13].length > 0 &&
          row[13] != ""
            ? row[13] + "#" + readingRangeValue[i][13]
            : row[13],
        reactionTime:
          readingRangeValue[i][14] != null &&
          readingRangeValue[i][14].length > 0 &&
          row[14] != ""
            ? row[14] + "#" + readingRangeValue[i][14]
            : row[14],
        gravity:
          readingRangeValue[i][15] != null &&
          readingRangeValue[i][15].length > 0 &&
          row[15] != ""
            ? row[15] + "#" + readingRangeValue[i][15]
            : row[15],
        drift:
          readingRangeValue[i][16] != null &&
          readingRangeValue[i][16].length > 0 &&
          row[16] != ""
            ? row[16] + "#" + readingRangeValue[i][16]
            : row[16],

        cmv:
          readingRangeValue[i][17] != null &&
          readingRangeValue[i][17].length > 0 &&
          row[17] != ""
            ? row[17] + "#" + readingRangeValue[i][17]
            : row[17],
        mpe:
          readingRangeValue[i][18] != null &&
          readingRangeValue[i][18].length > 0 &&
          row[18] != ""
            ? row[18] + "#" + readingRangeValue[i][18]
            : row[18],
        materialDensity:
          readingRangeValue[i][19] != null &&
          readingRangeValue[i][19].length > 0 &&
          row[19] != ""
            ? row[19] + "#" + readingRangeValue[i][19]
            : row[19],
      }));
    } else {
      rows = readingRows.map((row, i) => ({
        standardId: Number(insertedID),
        rangeName: `${row[1]}#${
          readingRangeValue[i][1] != null ? readingRangeValue[i][1] : ""
        }|${row[2]}#${
          readingRangeValue[i][2] != null ? readingRangeValue[i][2] : ""
        }`,
        mode:
          readingRangeValue[i][3] != null &&
          readingRangeValue[i][3].length > 0 &&
          row[3] != ""
            ? row[3] + "#" + readingRangeValue[i][3]
            : row[3],
        parameter:
          readingRangeValue[i][4] != null &&
          readingRangeValue[i][4].length > 0 &&
          row[4] != ""
            ? row[4] + "#" + readingRangeValue[i][4]
            : row[4],
        paratype:
          readingRangeValue[i][5] != null &&
          readingRangeValue[i][5].length > 0 &&
          row[5] != ""
            ? row[5] + "#" + readingRangeValue[i][5]
            : row[5],
        uncertainty:
          readingRangeValue[i][6] != null &&
          readingRangeValue[i][6].length > 0 &&
          row[6] != ""
            ? row[6] + "#" + readingRangeValue[i][6]
            : row[6],
        accuracy:
          readingRangeValue[i][7] != null &&
          readingRangeValue[i][7].length > 0 &&
          row[7] != ""
            ? row[7] + "#" + readingRangeValue[i][7]
            : row[7],
        stability:
          readingRangeValue[i][10] != null &&
          readingRangeValue[i][10].length > 0 &&
          row[10] != ""
            ? row[10] + "#" + readingRangeValue[i][10]
            : row[10],
        uniformity:
          readingRangeValue[i][11] != null &&
          readingRangeValue[i][11].length > 0 &&
          row[11] != ""
            ? row[11] + "#" + readingRangeValue[i][11]
            : row[11],
        axialUniformity:
          readingRangeValue[i][12] != null &&
          readingRangeValue[i][12].length > 0 &&
          row[12] != ""
            ? row[12] + "#" + readingRangeValue[i][12]
            : row[12],
        radialUniformity:
          readingRangeValue[i][13] != null &&
          readingRangeValue[i][13].length > 0 &&
          row[13] != ""
            ? row[13] + "#" + readingRangeValue[i][13]
            : row[13],
        reactionTime:
          readingRangeValue[i][14] != null &&
          readingRangeValue[i][14].length > 0 &&
          row[14] != ""
            ? row[14] + "#" + readingRangeValue[i][14]
            : row[14],
        gravity:
          readingRangeValue[i][15] != null &&
          readingRangeValue[i][15].length > 0 &&
          row[15] != ""
            ? row[15] + "#" + readingRangeValue[i][15]
            : row[15],
        drift:
          readingRangeValue[i][16] != null &&
          readingRangeValue[i][16].length > 0 &&
          row[16] != ""
            ? row[16] + "#" + readingRangeValue[i][16]
            : row[16],
        cmv:
          readingRangeValue[i][17] != null &&
          readingRangeValue[i][17].length > 0 &&
          row[17] != ""
            ? row[17] + "#" + readingRangeValue[i][17]
            : row[17],
        mpe:
          readingRangeValue[i][18] != null &&
          readingRangeValue[i][18].length > 0 &&
          row[18] != ""
            ? row[18] + "#" + readingRangeValue[i][18]
            : row[18],
        materialDensity:
          readingRangeValue[i][19] != null &&
          readingRangeValue[i][19].length > 0 &&
          row[19] != ""
            ? row[19] + "#" + readingRangeValue[i][19]
            : row[19],
      }));
    }
    let url = BASE_URL;
    axiosWithToken
      .post(url + "standardRanges/bulk", rows)
      .then((res) => {
        toast.success("Standard created successfully!");
        // props.setLoader(false);
      })
      .catch((err) => {
        props.setLoader(false);
      });
  } else {
    let rangeIds = [];
    for (let i = 0; i < readingRows.length; i++) {
      rows.push({
        standardId: Number(params.id),
        fromRange: readingRows[i][1],
        toRange: readingRows[i][2],
        mode: readingRows[i][3],
        parameter: readingRows[i][4],
        paratype: readingRows[i][5],
        uncertainty: readingRows[i][6],
        accuracy: readingRows[i][7],
        percentOfOutput: readingRows[i][8],
        plusValue: readingRows[i][9],
        stability: readingRows[i][10],
        uniformity: readingRows[i][11],
        axialUniformity: readingRows[i][12],
        radialUniformity: readingRows[i][13],
        reactionTime: readingRows[i][14],
        gravity: readingRows[i][15],
        drift: readingRows[i][16],
        id: readingRows[i][0],
        cmv: readingRows[i][17],
        mpe: readingRows[i][18],
        materialDensity: readingRows[i][19],
      });
      Number(readingRows[i][0]) && rangeIds.push(readingRows[i][0]);
    }

    const finalArr = [];
    for (let i = 0; i < rows.length; i++) {
      // for (let j = 0; j < readingRangeValue.length; j++) {
      finalArr.push({
        id: rows[i].id,
        standardId: Number(params.id),
        rangeName: `${rows[i].fromRange}#${
          readingRangeValue[i][1] != null ? readingRangeValue[i][1] : ""
        }|${rows[i].toRange}#${
          readingRangeValue[i][2] != null ? readingRangeValue[i][2] : ""
        }`,
        mode: rows[i].mode,
        parameter: rows[i].parameter,
        paratype: rows[i].paratype,
        uncertainty:
          readingRangeValue[i][6] != null && rows[i].uncertainty !== null
            ? rows[i].uncertainty.split("#")[0] + "#" + readingRangeValue[i][6]
            : rows[i].uncertainty,
        accuracy:
          readingRangeValue[i][7] != null && rows[i].accuracy !== null
            ? rows[i].accuracy.split("#")[0] + "#" + readingRangeValue[i][7]
            : rows[i].accuracy,
        percentOfOutput:
          readingRangeValue[i][8] != null && rows[i].percentOfOutput !== null
            ? rows[i].percentOfOutput.split("#")[0] +
              "#" +
              readingRangeValue[i][8]
            : rows[i].percentOfOutput,
        plusValue:
          readingRangeValue[i][9] != null && rows[i].plusValue !== null
            ? rows[i].plusValue.split("#")[0] + "#" + readingRangeValue[i][9]
            : rows[i].plusValue,
        stability:
          readingRangeValue[i][10] != null && rows[i].stability !== null
            ? rows[i].stability.split("#")[0] + "#" + readingRangeValue[i][10]
            : rows[i].stability,
        uniformity:
          readingRangeValue[i][11] != null && rows[i].uniformity
            ? rows[i].uniformity.split("#")[0] + "#" + readingRangeValue[i][11]
            : rows[i].uniformity,
        axialUniformity:
          readingRangeValue[i][12] != null && rows[i].axialUniformity
            ? rows[i].axialUniformity.split("#")[0] +
              "#" +
              readingRangeValue[i][12]
            : rows[i].axialUniformity,
        radialUniformity:
          readingRangeValue[i][13] != null && rows[i].radialUniformity
            ? rows[i].radialUniformity.split("#")[0] +
              "#" +
              readingRangeValue[i][13]
            : rows[i].radialUniformity,
        reactionTime:
          readingRangeValue[i][14] != null && rows[i].reactionTime
            ? rows[i].reactionTime.split("#")[0] +
              "#" +
              readingRangeValue[i][14]
            : rows[i].reactionTime,
        gravity:
          readingRangeValue[i][15] != null && rows[i].gravity != null
            ? rows[i].gravity.split("#")[0] + "#" + readingRangeValue[i][15]
            : rows[i].gravity,
        drift:
          readingRangeValue[i][16] != null && rows[i].drift !== null
            ? rows[i].drift.split("#")[0] + "#" + readingRangeValue[i][16]
            : rows[i].drift,
        cmv:
          readingRangeValue[i][17] != null && rows[i].cmv !== null
            ? rows[i].cmv.split("#")[0] + "#" + readingRangeValue[i][17]
            : rows[i].cmv,
        mpe:
          readingRangeValue[i][18] != null && rows[i].mpe !== null
            ? rows[i].mpe.split("#")[0] + "#" + readingRangeValue[i][18]
            : rows[i].mpe,
        materialDensity:
          readingRangeValue[i][19] != null && rows[i].materialDensity !== null
            ? rows[i].materialDensity.split("#")[0] +
              "#" +
              readingRangeValue[i][19]
            : rows[i].materialDensity,
      });
    }
    // insert/update records
    let patch = [];
    let post = [];
    finalArr.map((row) => {
      if (row.id) {
        patch.push(row);
      } else {
        delete row.id;
        post.push(row);
      }
    });
    var parsedData = JSON.parse(sessionStorage.getItem('masterEQPstandardRanges'));

    const updatedOldRanges = parsedData?.map(row => {
      const { lastModified,status, ...rest } = row;
      return rest;
    });
    const newStandardRangesId = compareOldAndNewReadings(updatedOldRanges, patch);
    patch = patch?.filter(row => newStandardRangesId?.includes(row.id));
    await Promise.all(
      patch
        .map((row) => {
          return axiosWithToken
            .patch(BASE_URL + `standardRanges/${row.id}`, row)
            .then((res) => {
              return res;
            })
            .catch((error) => {
              console.log(error);
              return error;
            });
        })
        .concat(
          post.length > 0
            ? [
                axiosWithToken
                  .post(BASE_URL + `standardRanges/bulk`, post)
                  .then((res) => {
                    return res;
                  })
                  .catch((error) => {
                    console.log(error);
                    return error;
                  }),
              ]
            : []
        )
    ).then((res) => {
      props.setLoader(false);
      toast.success("Standards updated successfully!");
    });
  }
};

const updateData = (
  props,
  standardDetails,
  srfObject,
  readingRows,
  readingRangeValue,
  params,
  percent
) => {
  let row = {
    standardName: standardDetails?.standardName,
    stId: standardDetails?.ids,
    serialNo: standardDetails?.serialNo,
    make: standardDetails?.make,
    model: standardDetails?.model,
    certificateNumber: standardDetails?.certificateNumber,
    traceability: standardDetails?.traceability,
    calDate: moment(standardDetails?.calDate).format("YYYY-MM-DD"),
    validUpto: standardDetails?.validUpto
      ? moment(standardDetails?.validUpto).format("YYYY-MM-DD")
      : null,
    certificateUrl: standardDetails?.certificateUrl ? standardDetails?.certificateUrl : null,
    disciplineId: srfObject.disciplineId,
    masterrange: srfObject.masterrange,
    masteraccuracy: srfObject.masteraccuracy,
    masterleastcount: srfObject.masterleastcount,
    type: standardDetails?.EQMType,
    materialType:
      standardDetails?.materialType === ""
        ? ""
        : standardDetails?.materialType,
    location: standardDetails?.location,
  };

  if (params.id == 0) {
    axiosWithToken
      .post(BASE_URL + "standards", row)
      .then((res) => {
        var insertedID = res.data.insertId;
        submitRanges(
          insertedID,
          readingRows,
          readingRangeValue,
          params,
          props,
          percent
        );
        props.setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        props.setLoader(false);
      });
  } else {
    axiosWithToken
      .patch(BASE_URL + `standards/${params.id}`, row)
      .then((res) => {
        submitRanges(
          "",
          readingRows,
          readingRangeValue,
          params,
          props,
          percent
        );
        props.setLoader(false);
      })
      .catch((error) => {
        console.log(error);
        props.setLoader(false);
      });
  }
};

export const handleSubmit = (
  props,
  standardDetails,
  srfObject,
  readingRows,
  readingRangeValue,
  params,
  percent
) => {
  props.setLoader(true);
  updateData(
    props,
    standardDetails,
    srfObject,
    readingRows,
    readingRangeValue,
    params,
    percent
  );
};
